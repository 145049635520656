<template>
    <custom-table
        :title="$t('pages.developer.customerManagement.customer.title')"
        :subTitle="$t('pages.developer.customerManagement.customer.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1">
                {{ record.email }}
            </a>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/developer/customer-management/customer/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-tooltip :content="$t('pages.developer.customerManagement.customer.selfLogin')" placement="top">
                    <a v-on:click="selfLogin(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr076.svg"/>
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";
import JwtService from "@/core/services/JwtService";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.developer.customerManagement.customer.cols.name"),
                    key: "firstname"
                },
                {
                    name: this.$t("pages.developer.customerManagement.customer.cols.surname"),
                    key: "lastname"
                },
                {
                    name: this.$t("pages.developer.customerManagement.customer.cols.email"),
                    scopedSlots: {customRender: "email"}
                },
                {
                    name: this.$t("pages.developer.customerManagement.customer.cols.group"),
                    key: "group.name"
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.developer.customer.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.developerManagement.customerManagement.customer"), [this.$t("menu.developerManagement.title"), this.$t("menu.developerManagement.customerManagement.title")]);
        this.$store.dispatch('developer/customer/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods:{
        deleteRecord(id){
            this.$store.dispatch("developer/customer/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/developer/customer-management/customer/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("developer/customer/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("developer/customer/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },

        selfLogin(record) {
            this.axios.post(this.endpoint.get('customer_self_login', 'developer'), {customer_id: record.id}).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data
                    JwtService.save('token', data.token.accessToken);
                    this.trustedWindowOpen('/');
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
    }
}
</script>

<style scoped>

</style>